import "./style.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorTileLayer from "ol/layer/VectorTile.js";
import Overlay from 'ol/Overlay.js';
import Layer from "ol/layer/WebGLTile.js";
import Source from "ol/source/ImageTile.js";
import TileWMS from "ol/source/TileWMS.js";
import { apply, applyStyle } from "ol-mapbox-style";
import {toStringHDMS} from 'ol/coordinate.js';
import {toLonLat, transformExtent, transform} from 'ol/proj.js';
import {buffer} from 'ol/extent';

/**
 * Elements that make up the popup.
 */
const container = document.getElementById("popup");
const content = document.getElementById("popup-content");
const closer = document.getElementById("popup-closer");

/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

/**
 * Add a click handler to hide the popup.
 * @return {boolean} Don't follow the href.
 */
closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

/**
 * Map Layers
 */

const wmsSource = new TileWMS({
  url: "https://geo-backend.inta.gob.ar/geoserver/ows",
  params: {
    LAYERS: "geonode:Mapa_COS_Nacional",
    TILED: true,
    STYLES: "geonode:Mapa_COS_Nacional",
  },
  attributions: " | Instituto Nacional de Tecnología Agropecuaria",
});

const OSMLayer = new Layer({
  source: new Source({
    attributions:
      'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
      'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  }),
});

const wmsLayer = new TileLayer({
  source: wmsSource,
});

/**
 * Map Configuration
 */

const view = new View({
  center: transform([-64.422960,-40.942972], "EPSG:4326", "EPSG:900913"),
  zoom: 4.6,
});


const map = new Map({
  layers: [OSMLayer, wmsLayer],
  overlays: [overlay],
  target: "map",
  view: view,
});

/**
 * References Layers (streets, states, countries)
 */

const referenceLayer = new VectorTileLayer({ declutter: true });
applyStyle(
  referenceLayer,
  "https://www.arcgis.com/sharing/rest/content/items/2a2e806e6e654ea78ecb705149ceae9f/resources/styles/root.json?f=pjson",
  {
    transformRequest(url, type) {
      if (type === "Source") {
        return new Request(
          url.replace("/VectorTileServer", "/VectorTileServer/")
        );
      }
    },
  }
).then((_layer) => {
  map.addLayer(referenceLayer);
});

/**
 * Gestures
 */

map.on("singleclick", function (evt) {
  const coordinate = evt.coordinate;

  const viewResolution = /** @type {number} */ (view.getResolution());
  const url = wmsSource.getFeatureInfoUrl(
    coordinate,
    viewResolution,
    "EPSG:3857",
    { INFO_FORMAT: "application/json" }
  );
  if (url) {
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        let value = undefined;

        if (json && json.features && json.features.length > 0) {
          const feature = json.features[0];
          if (feature && feature.properties) {
            value = feature.properties.GRAY_INDEX;
          }
        }

        if (value) {
          content.innerHTML = '<div style="padding-top:10px"><div style="color:black" data-theme="light">Almacenamiento COS</div><code data-theme="light">' + value + '</code></div>';
          overlay.setPosition(coordinate);  
        }
      });
  }
});

/**
 * Legend
 */

const updateLegend = function (resolution) {
  const graphicUrl = wmsSource.getLegendUrl(resolution);
  const img = document.getElementById('legend');
  img.src = graphicUrl;
};

// Initial legend
const resolution = map.getView().getResolution();
updateLegend(resolution);

// Update the legend when the resolution changes
map.getView().on('change:resolution', function (event) {
  const resolution = event.target.getResolution();
  updateLegend(resolution);
});
